<template>
<div>
  <van-cell-group title="审核">
    <van-cell title="申请借用" is-link size="large" icon="cart-o" to="/rent/new"  />
    <van-cell title="借出审核" is-link size="large" icon="edit" to="/rent/lst?for=VALID&STA=10" v-if="whale.user.validate('/rent/index')"/>
    <van-cell title="归还登记" is-link size="large" icon="revoke" to="/rent/lst?STA=15" v-if="whale.user.validate('/rent/index')"/>
  </van-cell-group>
  <van-cell-group title="查询">
    <van-cell title="我的记录" is-link size="large" icon="contact" to="/rent/lst?for=SUB" />
    <van-cell title="全部记录" is-link size="large" icon="search" to="/rent/lst" v-if="whale.user.validate('/rent/index')"/>
  </van-cell-group>
</div>
</template>
<script>
//v-if="whale.user.validate('/mat/valid/act?for=SUB&tp=100')"
export default {
  name: "index"

}
</script>
<style scoped></style>
